import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Storage } from "@ionic/storage";
import { Router } from "@angular/router";
import { LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import * as $ from 'jquery';

@Injectable({
   providedIn: 'root'
})

export class VarsService {
   // Global Urls
   public apiDrivers  = "http://localhost:3232/api/";
   //public apiDrivers  = " https://driversapi.stoapps.net/api/";
   
   //public filesUrl = "http://localhost/";  
   apiInit  = 'https://stephytrackingcloud.com/stoapitest.php';
   apiUrl  = null;
   private userBS = new BehaviorSubject<object>(null);
   user = this.userBS.asObservable();
   staticUser:object;
   currentUser:any = {role: ''};
   defaultSerial = 'expert';
   companyInfo;
   parameters: any = {UserID: '', void: 1, serial: ''};
   loadingC;

   constructor(
      private storage: Storage,
      private router: Router,
      public loadingController: LoadingController,
   ) {
      this.presentLoading(1);
   }

   setUser(data, alias) {
      this.userBS.next(data);
      (data) ? this.storage.set("user-DriversSto-" + alias, data) : this.storage.set("user-DriversSto-" + alias, null);
      (data) ? this.currentUser = data : null;
   }

   getCompany(alias, id, callback) {
      this.parameters.serial = alias;
      this.parameters.void = id;
      const settings = {
         "crossOrigin": true,
         "url": this.apiInit + '?Serial=' +  alias,
         "method": "GET",
      }

      this.storage.get('user-DriversSto-' + alias).then((val) => {
        if (val) {
          this.currentUser = val;
        }
      });

      $.ajax(settings).done((response) => {
         const data = JSON.parse(response.replace(/'/g, '"'));
         this.apiUrl  = data.SerialURL;
         callback('done');
         const url = this.apiUrl + "?Module=GetCompanyDataAPI&Serial=" + alias  + "&VirtualOfficeID=" + id;
         const settings = {
            "url": url,
            "method": "POST",
         }
         $.ajax(settings).done((response) => {
            this.companyInfo = JSON.parse(response.replace(/'/g, '"'));
         }).fail((xhr, err) => {
               //this.getCompany(alias, id);
         });
       }).fail((xhr, err) => { 
         alert('Serial Error');
      }); 
   }

   getType(id) {
      if (this.currentUser) {
          if (this.currentUser.role == 's') {
            return '&ShipperID=' + id + '&ConsigneeID=&AgentID=';
          }

          if (this.currentUser.role == 'a') {
            return '&AgentID=' + id + '&ShipperID=&ConsigneeID=';
          }

          if (this.currentUser.role == 'c') {
            return '&ConsigneeID=' + id + '&ShipperID=&AgentID=';
          }
      }
      return '&ShipperID=&ConsigneeID=&AgentID=';
   }

   checkSesionTime(val) {
      if (val == false) {
         this.storage.set("user-DriversSto-" + this.parameters.serial, null);
         this.router.navigate([this.parameters.serial + '/'  +  this.parameters.void + '/login/' + this.currentUser.role]);
         setTimeout(() => {
           location.reload();
         }, 150);
      }
   }

   toNum(num) {
      return Number(num.replace(/,/g, ''));
   }

   fixNum(num) {
      return Number(num).toFixed(2);
   }

   async presentLoading(time) {
       this.loadingC = await this.loadingController.create({
         duration: time
       });
       await this.loadingC.present();
   }

   dismissLoading() {
      this.loadingC.dismiss();
   }

   goPage(page) {
      this.router.navigate([this.parameters.serial + '/' + this.parameters.void  + page]);
   }

   goLogin() {
     console.log('this.currentUser', this.currentUser);
      this.router.navigate([this.parameters.serial + '/' + this.parameters.void  + '/login/' + (this.currentUser ?  this.currentUser['role'] : 'c')]);
   }

   goLogin2(serial, vid, role) {
      this.router.navigate([serial + '/' + vid  + '/login/' + role]);
   }

}
