import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: ':serial/:void/login/:type',
    loadChildren: './login/login.module#LoginPageModule'
  },
  {
    path: ':serial/:void/home',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: ':serial/:void/drivers',
    loadChildren: './drivers/drivers.module#DriversPageModule'
  },
  {
    path: ':serial/:void/deliveries',
    loadChildren: './deliveries/deliveries.module#DeliveriesPageModule'
  },
  {
    path: ':serial/:void/login',
    loadChildren: './login/login.module#LoginPageModule'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
